<template>
	<main>
		<h1>Integrations</h1>

		<ResponsiveTable :headers="['LTI Platforms', '']" :rows="platforms" />

		<BaseButton type="submit" :href="{name: 'AdminIntegrationsAddPlatform'}">
			Add platform
		</BaseButton>
	</main>
</template>

<script>
	import Backend from '../../inc/backend';
	import BaseButton from '../../components/BaseButton';
	import ResponsiveTable from '../../components/ResponsiveTable';
	import {roleGuard} from '../../inc/auth';

	export default {
		...roleGuard('Courses', 'any', 'teacher'),
		components: {
			BaseButton,
			ResponsiveTable
		},
		data() {
			return {
				platforms: [],
				tools: []
			};
		},
		created() {
			Backend.get('integrations/platforms').then(res => {
				this.platforms = res.data.map(platform => [
					{label: platform.name},
					{label: 'Edit', href: {name: 'AdminIntegrationsEditPlatform', params: {id: platform.id}}}
				]);
			});
		}
	};
</script>